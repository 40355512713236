<template>
  <div class="connent_title">
    <div class="connent_header">
      <span><a href="/WorkOrderReport/WorkOrderReport/List">工单报表</a></span>
    </div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>列表</el-breadcrumb-item>
      <el-breadcrumb-item><a href="/WorkOrderReport/WorkOrderReport/List">工单报表</a></el-breadcrumb-item>
      <el-breadcrumb-item>工单报表</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
  <el-form :inline="true" style="margin-top: 20px">


    <el-form-item label="维修时间段">
      <el-date-picker v-model="searchData.reportDate" type="daterange" range-separator="至"
                      start-placeholder="开始日期" end-placeholder="结束日期" value-format="YYYY-MM-DD">
      </el-date-picker>
    </el-form-item>


    <el-form-item>
      <el-button-group>
        <el-button type="success" @click="onSearch" v-hasPermission="'/Api/WorkOrderReport/List'">查询
        </el-button>
        <el-button type="primary" @click="onExport" v-hasPermission="'/Api/WorkOrderReport/List'">导出</el-button>
        <!--     <el-button type="primary" @click="onExport" v-hasPermission="'/Api/WorkOrderReport/getReport'">打印</el-button> -->
      </el-button-group>
    </el-form-item>

    <el-form-item label="语言" prop="ReportLanguage">
      <el-select v-model="searchData.ReportLanguage" placeholder="中文" style="width: 100%" @change="getHeader">
        <el-option key="中文" label="中文" value="ch"/>
        <el-option key="English" label="English" value="en"/>
        <el-option key="日本語" label="日本語" value="ja"/>
      </el-select>
    </el-form-item>
  </el-form>


  <!--  <el-table-->
  <!--      :data="tableData"-->
  <!--      v-loading="loading"-->
  <!--      style="width: 100%;border: 1px solid #EBEEF5;border-radius: 5px;">-->

  <!--    <el-table-column key="compName" label="客户名称" prop="compName"></el-table-column>-->

  <!--    <el-table-column label="报修类型">-->
  <!--      <el-table-column key="totalRepair" label="总报修次数" prop="totalRepair"></el-table-column>-->
  <!--      <el-table-column key="conservative" label="保守次数" prop="conservative"></el-table-column>-->
  <!--      <el-table-column key="periodicInspection" label="定检次数" prop="periodicInspection"></el-table-column>-->
  <!--      <el-table-column key="paid" label="有偿次数" prop="paid"></el-table-column>-->
  <!--      <el-table-column key="napin" label="纳品次数" prop="napin"></el-table-column>-->
  <!--    </el-table-column>-->

  <!--    <el-table-column label="报修项目">-->
  <!--      <el-table-column key="serverRepair" label="服务器报修" prop="serverRepair"></el-table-column>-->
  <!--      <el-table-column key="networkRepair" label="网络报修" prop="networkRepair"></el-table-column>-->
  <!--      <el-table-column key="pcRepair" label="PC报修" prop="pcRepair"></el-table-column>-->
  <!--      <el-table-column key="weakCurrent" label="弱电报修" prop="weakCurrent"></el-table-column>-->
  <!--      <el-table-column key="otherRepair" label="其他报修" prop="otherRepair"></el-table-column>-->
  <!--    </el-table-column>-->

  <!--    <el-table-column label="对应时间">-->
  <!--      <el-table-column key="totalMovingTime" label="总移动时间" prop="totalMovingTime"></el-table-column>-->
  <!--      <el-table-column key="totalCorrespondingTime" label="总对应时间" prop="totalCorrespondingTime"></el-table-column>-->
  <!--    </el-table-column>-->

  <!--  </el-table>-->

  <el-table :data="tableData" v-loading="loading" style="width: 100%;border: 1px solid #EBEEF5;border-radius: 5px;">

    <template v-for="col in cols" :key="col.prop">
      <el-table-column v-if="col.child == null" :prop="col.prop" :label="col.label"></el-table-column>

      <el-table-column v-else :label="col.label">
        <template v-for="col_child in col.child" :key="col_child.prop">
          <el-table-column :prop="col_child.fieldName" :label="col_child.displayName"></el-table-column>
        </template>
      </el-table-column>
    </template>
  </el-table>

  <!--  <el-table-->
  <!--      :data="tableData"-->
  <!--      v-loading="loading"-->
  <!--      style="width: 100%;border: 1px solid #EBEEF5;border-radius: 5px;">-->

  <!--    <template v-for="col in cols" :key="col.prop">-->
  <!--      <el-table-column  :prop="col.prop" :label="col.label" >-->
  <!--      </el-table-column>-->
  <!--    </template>-->
  <!--  </el-table>-->

  <!--    <el-table-column key="compName" :prop="col.prop" :label="col.label"></el-table-column>-->
  <!--    <el-table-column label="报修类型">-->
  <!--      <el-table-column key="totalRepair" :label="总报修次数" prop="totalRepair"></el-table-column>-->
  <!--      <el-table-column key="conservative" :label="保守次数" prop="conservative"></el-table-column>-->
  <!--      <el-table-column key="periodicInspection" :label="定检次数" prop="periodicInspection"></el-table-column>-->
  <!--      <el-table-column key="paid" :label="有偿次数" prop="paid"></el-table-column>-->
  <!--      <el-table-column key="napin" :label="纳品次数" prop="napin"></el-table-column>-->
  <!--    </el-table-column>-->

  <!--    <el-table-column label="报修项目">-->
  <!--      <el-table-column key="serverRepair" label="服务器报修" prop="serverRepair"></el-table-column>-->
  <!--      <el-table-column key="networkRepair" label="网络报修" prop="networkRepair"></el-table-column>-->
  <!--      <el-table-column key="pcRepair" label="PC报修" prop="pcRepair"></el-table-column>-->
  <!--      <el-table-column key="weakCurrent" label="弱电报修" prop="weakCurrent"></el-table-column>-->
  <!--      <el-table-column key="otherRepair" label="其他报修" prop="otherRepair"></el-table-column>-->
  <!--    </el-table-column>-->

  <!--    <el-table-column label="对应时间">-->
  <!--      <el-table-column key="totalMovingTime" label="总移动时间" prop="totalMovingTime"></el-table-column>-->
  <!--      <el-table-column key="totalCorrespondingTime" label="总对应时间" prop="totalCorrespondingTime"></el-table-column>-->
  <!--    </el-table-column>-->

  <!--  </el-table>-->

  <el-pagination background :pager-count="11" layout="total, prev, pager, next, jumper" :total="pageData.total"
                 :page-size="pageData.size" :current-page="pageData.current" @current-change="changePage">
  </el-pagination>


</template>

<script>
import axios from '@/utils/axios'


export default {
  name: "tableColumn",
  props: {
    coloumnHeader: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      cols: [
        // {prop: "compName", label: "",child:[
        //     // {prop: "compName", label: "客户名称"}
        //   ]
        //      },
        // {prop: "totalRepair", label: "总报修次数",child:null},
        // {prop: "repairType", label: "报修类型", child: [
        //     {prop: "conservative", label: "保守次数"},
        //     {prop: "periodicInspection", label: "定检次数"},
        //     {prop: "paid", label: "有偿次数",child:null},
        //     {prop: "napin", label: "纳品次数"},
        //   ]},
        // {prop: "repairProject", label: "报修项目",child:[
        //     {prop: "serverRepair", label: "服务器报修"},
        //     {prop: "networkRepair", label: "网络报修"},
        //     {prop: "pcRepair", label: "PC报修"},
        //     {prop: "weakCurrent", label: "弱电报修"},
        //     {prop: "otherRepair", label: "其他报修"},
        //   ]},
        // {prop: "correspondingTime", label: "对应时间",child: [
        //     {prop: "totalMovingTime", label: "总移动时间"},
        //     {prop: "totalCorrespondingTime", label: "总对应时间"},
        //   ]},

      ], //初始化表头
      tableData1: [{
        compName: "24",
        totalRepair: "20",
        conservative: "18",
        periodicInspection: "2",
        paid: "2",
        napin: "2",
        serverRepair: "2",
        networkRepair: "2",
        pcRepair: "2",
        weakCurrent: "2",
        otherRepair: "2",
        totalMovingTime: "2",
        totalCorrespondingTime: "2"
      }

      ],
      // 全局变量，用于获得配置信息等
      global: window.global,

      loading: false,

      // 分页信息
      pageData: window.global.config.page,
      // 搜索区内容
      searchData: {},
      searchOptions: {},
      // 表格数据
      tableData: [],

    }
  },
  components: {},
  created() {
    this.getTableData(),
        this.getHeader();
  },
  methods: {
    // 搜索事件
    onSearch() {
      this.getTableData()
    },
    // 表头语言
    getHeader(val) {
      axios.get(`/ReportLanguage/getHeader?language=` + val).then(res => {
        this.cols = [];
        //   this.tableData1 = res.data.list
        res.data.forEach(item => {
          let tabHeader = {};
          tabHeader.prop = item.fieldName;
          tabHeader.label = item.displayName;
          tabHeader.child = item.childrenHeader;
          this.cols.push(tabHeader);
        });
      }).catch(error => {
        this.loading = false
      });
    },

    // // 表头语言
    // getHeader(val) {

    // 	var data = {}

    // 	data.language=val
    // 	data.stat = "getlaug"

    // 	axios.get('/WorkOrderReport/List',{
    // 		params: Object.assign({}, data, this.pageData)
    // 	}).then(res => {
    // 		console.log("21321123++++++++++++++++++++++++", res)
    // 		this.cols = [];
    // 		//   this.tableData1 = res.data.list
    // 		res.data.forEach(item => {
    // 			let tabHeader = {};
    // 			tabHeader.prop = item.fieldName;
    // 			tabHeader.label = item.displayName;
    // 			tabHeader.child = item.childrenHeader;
    // 			this.cols.push(tabHeader);
    // 		});
    // 		console.log("数据111111", this.cols)
    // 	}).catch(error => {
    // 		this.loading = false
    // 	});
    // },


    // 导出事件
    onExport() {
      axios.exportExecl(`/WorkOrderReport/Export`, `工单报表`, { //url: 接口地址
        params: this.searchData,
        responseType: `arraybuffer` //一定要写
      });
    },
    // 增加事件
    onCreate() {
      this.edit_view.title = "新增";
      this.edit_view.type = 'add'
      this.edit_view.data = {}
      this.edit_view.show = true;
    },
    // 查看事件
    onInfo(index, row) {
      this.info_view.title = "详情";
      this.info_view.data = row
      this.info_view.show = true;
    },
    // 删除事件
    onDelete(row) {
      this.loading = true
      axios.post('/WorkOrderReport/Remove', {
        id: row.id,
        fackId: row.fackId
      }).then(() => {
        ElMessage.success('删除成功')
        this.getTableData()
      }).catch(() => {
        this.getTableData()
      })
    },
    // 编辑事件
    onEdit(index, row) {
      this.edit_view.title = "修改";
      this.edit_view.type = 'edit'
      this.edit_view.data = row;
      this.edit_view.show = true;
    },
    // 返回事件
    onClose() {
      this.edit_view.show = false;
      this.info_view.show = false;
    },
    // // 获取列表
    // getTableData() {
    // 	this.loading = true
    // 	var that = this

    // 	var data = {}
    // 	if (this.searchData.reportDate) {
    // 		data.startTime = this.searchData.reportDate[0]
    // 		data.endTime = this.searchData.reportDate[1]
    // 	}
    // 	data.stat = "getlist"
    // 	axios.get('/WorkOrderReport/List', {
    // 		params: Object.assign({}, data, this.pageData)
    // 	}).then(res => {
    // 		that.tableData = res.data.list
    // 		that.pageData.total = res.data.total
    // 		that.loading = false
    // 	}).catch(error => {
    // 		that.loading = false
    // 	})
    // 	console.log(that.tableData)
    // },
    // 获取列表
    getTableData() {
      this.loading = true
      var that = this
      var data = {}
      data.current =  this.pageData.current
      if (that.searchData.reportDate) {
        data.startTime = that.searchData.reportDate[0]
        data.endTime = that.searchData.reportDate[1]
      }
      axios.post('/WorkOrderReport/getReport', {
        params: Object.assign({}, data, that.pageData)
      }).then(res => {
        that.tableData = res.data.list
        that.pageData.total = res.data.total
        that.loading = false
      }).catch(error => {
        that.loading = false
      })
      console.log(that.tableData)
    },
    // 分页事件
    changePage(page) {
      this.pageData.current = page
      this.getTableData()
    },

  }
}
</script>
